

    import { defineComponent, getCurrentInstance, nextTick, onMounted, ref } from 'vue'
    import Grid from '../components/Grid/Grid.vue'
    import Column from '../components/Grid/Column.vue'
    import Breadcrumb from '../components/Breadcrumb.vue'
    import AgileTeams from '../components/AgileTeams.vue'
    import Button from '../components/Button.vue'
    import Container from '../components/Container.vue'
    import DynamicContent from '../components/DynamicContent.vue'
    import PageHeader from '../components/PageHeader.vue'
    import OpenSourcePackage from '../components/OpenSourcePackage.vue'
    import OpenSourcePackageSkeleton from '../components/OpenSourcePackageSkeleton.vue'
    import Heading from '../components/Heading.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Projects from '../components/Projects.vue'
    import Partners from '../components/Partners.vue'
    import Picture from '../components/Picture.vue'
    import CallToAction from '../components/CallToAction.vue'
    import TitleAndList from '../components/TitleAndList.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useTheme } from '../compositions/UseTheme'
    import { useRoute } from 'vue-router'
    import { useCms } from '../compositions/UseCms'
    import BlogCard from '../components/Blog/BlogCard.vue'

    export default defineComponent({
        name: 'Services',
        components: {
            CallToAction,
            AgileTeams,
            Breadcrumb,
            Button,
            DynamicContent,
            Grid,
            Column,
            Container,
            PageHeader,
            Projects,
            OpenSourcePackage,
            OpenSourcePackageSkeleton,
            Partners,
            Picture,
            Paragraph,
            Heading,
            MetaInfo,
            TitleAndList,
            ThemeChanger,
            BlogCard
        },
        beforeRouteEnter(to, from, next) {

            useCms().serviceData(to.params.slug as string) ? next() : next({ name: Route.NotFound })

        },
        setup() {

            const refreshAOSHard = getCurrentInstance()?.appContext.config.globalProperties.$aos?.refreshHard

            const route = useRoute()

            const openSourcePackages = ref([])
            const isLoading = ref<boolean>(true)
            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            // console.log(service?.seo_structured_data)

            onMounted(async() => {

                const openSourceUrls = []

                if (Array.isArray(service.open_source_items)) {

                    for (const item of service.open_source_items) {

                        openSourceUrls.push(fetch(item.api_url))

                    }

                }

                openSourcePackages.value.push(
                    ...await Promise.all(openSourceUrls)
                        .then(responses => responses.map(response => response.json()))
                        .then(responses => Promise.all(responses))
                )

                isLoading.value = false

                await nextTick()
                await refreshAOSHard?.()

            })

            const { serviceData } = useCms()
            const service = serviceData(route.params.slug)

            return {
                Theme,
                Route,
                service,
                openSourcePackages,
                isLoading
            }

        }
    })

