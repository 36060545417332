import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-034a614a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md:mx-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Grid, { class: "py-12 border border-black border-1" }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          span: [ 2, 3 ],
          class: "justify-start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Heading, {
              tag: "div",
              innerHTML: _ctx.label,
              class: "text-center title md:text-left"
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          span: [ 2, 3 ],
          class: "justify-center mt-12 md:mt-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              "purple-solid": "",
              route: _ctx.action?.link ?? _ctx.Route.Contact
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
              ]),
              _: 1
            }, 8, ["route"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}