import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!

  return (_openBlock(), _createBlock(_component_Column, {
    span: [ 2, _ctx.small ? 3 : 6 ],
    push: [ 0, 0 ],
    class: _normalizeClass(["blog-card", { '--left': _ctx.small && _ctx.isLeft, '--right': _ctx.small && !_ctx.isLeft, '--no-padding': _ctx.noPadding }])
  }, {
    default: _withCtx(() => [
      (_ctx.blog?.cover)
        ? (_openBlock(), _createBlock(_component_Anchor, {
            key: 0,
            route: { name: _ctx.Route.BlogPost, params: { slug: _ctx.blog.slug } },
            class: "blog-card__link --cover"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Picture, {
                image: _ctx.blog.cover,
                auto: ""
              }, null, 8, ["image"])
            ]),
            _: 1
          }, 8, ["route"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Column, {
        span: [ 2, _ctx.small ? 3 : 5 ],
        class: _normalizeClass([ _ctx.small ? '' : 'mx-auto' ])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Anchor, {
            route: { name: _ctx.Route.BlogPost, params: { slug: _ctx.blog.slug } },
            class: _normalizeClass([[ _ctx.blog?.cover ? _ctx.small ? 'mt-10' : 'mt-12' : 'mt-0' ], "blog-card__link --title"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Heading, {
                tag: _ctx.small ? 'h3' : 'h2',
                "no-margin": "",
                innerHTML: _ctx.blog.title,
                class: "text-28px"
              }, null, 8, ["tag", "innerHTML"])
            ]),
            _: 1
          }, 8, ["route", "class"]),
          (_ctx.blog?.introduction)
            ? (_openBlock(), _createBlock(_component_Paragraph, {
                key: 0,
                innerHTML: _ctx.blog.introduction,
                class: "text-base mt-7",
                "no-margin": ""
              }, null, 8, ["innerHTML"]))
            : _createCommentVNode("", true),
          (_ctx.isNormalBlogPost(_ctx.blog))
            ? (_openBlock(), _createBlock(_component_Paragraph, {
                key: 1,
                class: "pt-5",
                gray: "",
                smaller: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.blog.updated_at) + " · Written by " + _toDisplayString(_ctx.blog?.author?.name ?? _ctx.blog?.updated_by?.name ?? ''), 1)
                ]),
                _: 1
              }))
            : (_ctx.isShareBlogPost(_ctx.blog))
              ? (_openBlock(), _createBlock(_component_Paragraph, {
                  key: 2,
                  class: "pt-5",
                  gray: "",
                  smaller: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.blog.updated_at), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_Paragraph, {
                  key: 3,
                  class: "pt-5",
                  gray: "",
                  smaller: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.blog.blog_updated_at) + " · Written by " + _toDisplayString(_ctx.blog?.author?.name ?? _ctx.blog?.updated_by?.name ?? ''), 1)
                  ]),
                  _: 1
                }))
        ]),
        _: 1
      }, 8, ["span", "class"])
    ]),
    _: 1
  }, 8, ["span", "class"]))
}