
    import { defineComponent } from 'vue'
    import { useCms } from '../compositions/UseCms'
    import Grid from './Grid/Grid.vue'
    import Column from './Grid/Column.vue'
    import Heading from './Heading.vue'
    import Paragraph from './Paragraph.vue'
    import Button from './Button.vue'
    import { Route } from '../enums/Route'

    export default defineComponent({
        name: 'AgileTeams',
        components: {
            Grid,
            Column,
            Heading,
            Button,
            Paragraph
        },
        props: {
            title: { type: String, required: true },
            content: { type: String, required: true },
            link: { type: Object, required: true },
            linkLabel: { type: String, required: true }
        },
        setup() {

            const { agileTeams } = useCms()

            return {
                agileTeams,
                Route
            }

        }
    })
