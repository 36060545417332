

    import { defineComponent } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'

    export default defineComponent({
        name: 'OpenSourcePackageSkeleton',
        components: {
            Grid,
            Column
        }
    })

