

    import { PropType, defineComponent } from 'vue'
    import { useBlogType } from '../../compositions/UseBlogType'
    import { useCms } from '../../compositions/UseCms'
    import { useTheme } from '../../compositions/UseTheme'
    import { Route } from '../../enums/Route'
    import Anchor from '../Anchor.vue'
    import Column from '../Grid/Column.vue'
    import Heading from '../Heading.vue'
    import Paragraph from '../Paragraph.vue'
    import Picture from '../Picture.vue'
    import { BlogPostInterface } from '../../Interfaces/BlogPostInterface'

    export default defineComponent({
        name: 'BlogCard',
        components: {
            Column,
            Anchor,
            Picture,
            Heading,
            Paragraph
        },
        props: {
            blog: { type: Object as PropType<BlogPostInterface>, required: true },
            small: { type: Boolean, default: false },
            isLeft: { type: Boolean, default: true },
            noPadding: { type: Boolean, default: false }
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { blog, blogs } = useCms()
            const { isNormalBlogPost, isShareBlogPost } = useBlogType()

            return {
                Route,
                cms: blog,
                blogs,
                isNormalBlogPost,
                isShareBlogPost
            }

        }
    })

