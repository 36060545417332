import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, {
    class: "p-40 border border-black border-1",
    centered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, { span: [ 2, 3 ] }, {
        default: _withCtx(() => [
          _createVNode(_component_Heading, {
            class: "agile-teams__title",
            tag: "div",
            innerHTML: _ctx.title
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_Button, {
            class: "mt-12",
            "purple-solid": "",
            route: _ctx.link?.slug ?? _ctx.Route.Contact
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.linkLabel ?? "Contact Us"), 1)
            ]),
            _: 1
          }, 8, ["route"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        class: "mt-16 md:mt-2.5",
        span: [ 2, 3 ]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Paragraph, {
            "no-margin": "",
            class: "lg:px-10 font-mabry-pro agile-teams__content",
            innerHTML: _ctx.content
          }, null, 8, ["innerHTML"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}