export function useBlogType() {

    function isNormalBlogPost(blog: any) {

        return blog?.blueprint?.handle === 'blogs'

    }

    function isShareBlogPost(blog: any) {

        return blog?.blueprint?.handle === 'share_blog_post'

    }

    return {
        isNormalBlogPost,
        isShareBlogPost
    }

}
