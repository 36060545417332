import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "breadcrumb",
  "aria-label": "Breadcrumb"
}
const _hoisted_2 = {
  key: 2,
  class: "text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_Column, null, {
        default: _withCtx(() => [
          _createElementVNode("nav", _hoisted_1, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paths, (path, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "inline"
                }, [
                  (index === _ctx.paths.length-1)
                    ? (_openBlock(), _createBlock(_component_Anchor, {
                        key: 0,
                        "aria-current": "page",
                        route: path.route,
                        class: "breadcrumb__link text-pink-300 uppercase font-normal",
                        bold: false
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(path.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["route"]))
                    : (_openBlock(), _createBlock(_component_Anchor, {
                        key: 1,
                        route: path.route,
                        class: "breadcrumb__link text-pink-300 uppercase font-normal",
                        bold: false
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(path.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["route"])),
                  (index !== _ctx.paths.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, " / "))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}