

    import { defineComponent, PropType } from 'vue'
    import Grid from './Grid/Grid.vue'
    import Column from './Grid/Column.vue'
    import Anchor from './Anchor.vue'
    // import Paragraph from './Paragraph.vue'
    import { RouteLocation } from 'vue-router'

    interface PathInterface {
        route: RouteLocation | string,
        text: string
    }

    export default defineComponent({
        name: 'Breadcrumb',
        components: {
            Grid,
            Column,
            Anchor
        // Paragraph
        },
        props: {
            paths: { type: Array as PropType<PathInterface[]>, required: true }
        },
        setup(props) {

            return {
                data: props.paths.join(' / ').toUpperCase()
            }

        }
    })

