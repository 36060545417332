<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.4999 6.85V1H14.6499" stroke="#C000AF" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        <path d="M20.5 1L7.5 14" stroke="#C000AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M10.1 4.8999H2.3C1.58203 4.8999 1 5.48193 1 6.1999V19.1999C1 19.9179 1.58203 20.4999 2.3 20.4999H15.3C16.018 20.4999 16.6 19.9179 16.6 19.1999V11.3999"
            stroke="#C000AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
