import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExternalLink = _resolveComponent("ExternalLink")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Container = _resolveComponent("Container")!

  return (_ctx.featured)
    ? (_openBlock(), _createBlock(_component_Container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_Column, { span: [ 2, 5, 5, 4 ] }, {
                default: _withCtx(() => [
                  _createVNode(_component_Anchor, {
                    external: "",
                    route: _ctx.data?.link
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Heading, {
                        tag: "h2",
                        purple: "",
                        "no-margin": ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.data?.title) + " ", 1),
                          _createVNode(_component_ExternalLink, { class: "inline ml-2 osp__share lg:ml-3" })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["route"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_Grid, { class: "mt-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, { span: [ 2, 3, 4 ] }, {
                default: _withCtx(() => [
                  _createVNode(_component_Anchor, {
                    external: "",
                    route: _ctx.data?.link,
                    class: "mt-2 md:pr-8 lg:pr-16"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Picture, {
                        image: _ctx.data?.image,
                        height: _ctx.data?.image ? '100%' : '400px',
                        width: "100%",
                        cover: ""
                      }, null, 8, ["image", "height"])
                    ]),
                    _: 1
                  }, 8, ["route"])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                span: [ 2, 3, 2 ],
                class: "sm:mt-6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Paragraph, {
                    class: _normalizeClass(["osp__item", { '--missing-desc': !_ctx.data.description }]),
                    "no-margin": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.category?.title ?? ''), 1)
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(_component_Paragraph, {
                    class: "osp__item",
                    "no-margin": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isCNLocale ? `${_ctx.data.downloads}次下载` : `${_ctx.data.downloads} Downloads`), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Paragraph, {
                    class: "osp__item",
                    "no-margin": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isCNLocale ? `${_ctx.data.stars}个星标` : `${_ctx.data.stars} Stars`), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.data.description)
                    ? (_openBlock(), _createBlock(_component_Paragraph, {
                        key: 0,
                        class: "pt-10",
                        innerHTML: _ctx.data?.description
                      }, null, 8, ["innerHTML"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_Container, {
        key: 1,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                span: 4,
                class: "sm:mt-4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Anchor, {
                    external: "",
                    route: _ctx.data?.link,
                    class: _normalizeClass([ { 'md:pl-8': _ctx.featured } ])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Heading, {
                        tag: "h2",
                        "no-margin": "",
                        purple: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.data?.title) + " ", 1),
                          _createVNode(_component_ExternalLink, { class: "inline ml-2 osp__share lg:ml-3" })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["route", "class"]),
                  (_ctx.data.description)
                    ? (_openBlock(), _createBlock(_component_Paragraph, {
                        key: 0,
                        class: _normalizeClass([ 'pt-4', { 'md:pl-8': _ctx.featured } ]),
                        innerHTML: _ctx.data?.description
                      }, null, 8, ["class", "innerHTML"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                span: 2,
                class: "mt-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Paragraph, {
                    class: "osp__item",
                    "no-margin": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.category?.title ?? ''), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Paragraph, {
                    class: "osp__item",
                    "no-margin": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isCNLocale ? `${_ctx.data.downloads}次下载` : `${_ctx.data.downloads} Downloads`), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Paragraph, {
                    class: "osp__item",
                    "no-margin": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isCNLocale ? `${_ctx.data.stars}个星标` : `${_ctx.data.stars} Stars`), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}