
    import { defineComponent } from 'vue'
    import { Route } from '../enums/Route'
    import Button from './Button.vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Heading from './Heading.vue'

    export default defineComponent({
        name: 'CallToAction',
        components: { Button, Heading, Column, Grid },
        props: {
            action: { type: Object },
            label: { type: String, required: true },
            buttonLabel: { type: String, required: true }
        },
        setup() {

            return {
                Route
            }

        }
    })
